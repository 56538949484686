@use 'variables';

.mts-star-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.mts-star-item {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  line-height: 1;
}

.mts-star-on {
  color: variables.$yellow-dark;

  svg {
    fill: variables.$yellow-dark;
  }
}

.mts-star-off {
  color: variables.$grey;
}

.mts-star-description {
  font-size: 12px;
  font-weight: 700;
  color: variables.$grey-medium;
}