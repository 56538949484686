@use "sass:color";
@use 'variables';
@use '../../sass/vars.scss' as vars;

.mts-autocomplete-container {
  display: block;
  position: relative;
}

.mts-autocomplete {
  box-sizing: border-box;
  margin-top: 4px;
  padding: 0.125rem;
  background-color: color.adjust(variables.$white, $lightness: -2%);
  position: absolute;
  width: 100%;
  border-radius: 8px;
  z-index: 1000;
  height: auto;

  &-list {
    border-radius: 8px;
    border-top-width: 0;
    list-style: none;
    font-family: variables.$mts-font-family;
    margin: 4px 0;
    max-height: 150px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;

    >.active {}

    >li {
      color: variables.$blue-darkest;
      background-color: variables.$white;
      border-radius: variables.$mts-border-radius-lg;
      list-style-type: none;
      padding: 0.5rem 1rem;
      margin-bottom: 0.125rem;
      cursor: pointer;

      &.large {
        padding: 0.75rem 1.25rem !important;
        font-size: 1.25rem !important;
        margin-bottom: 0.25rem !important;
      }

      &:hover {
        background-color: variables.$blue-darkest;
        color: variables.$white;
      }
    }
  }

  .no-autocomplete {
    font-family: variables.$mts-font-family;
    font-weight: 500;
    padding: 0.5rem;
    margin: 0.25rem 0;
    border-radius: variables.$mts-border-radius-lg;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: variables.$grey-light;
  }

  ::-webkit-scrollbar-thumb {
    background-color: variables.$blue-darkest;
    border-radius: 10em;
    border: 2px solid #eaeaea;
  }
}

.mts-autocomplete-error {
  background-color: red;
  color: white;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  padding: 1px 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  border-radius: 60px;
  opacity: 0;
  z-index: 2;
}

.active {
  opacity: 1;
  visibility: visible;
  transition-duration: vars.time(slow);
  transition-delay: 0.1s;
}