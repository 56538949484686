@use 'variables';

$mts-card-height: null;
$mts-card-bg: variables.$grey-superlight;
$mts-card-margin: 0.5rem 0;
$mts-card-body-padding: 1rem;
$mts-card-border-radius: 0.5rem;

$mts-card-header-padding: 0.75rem 1rem;

.mts-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: $mts-card-height;
  word-wrap: break-word;
  background-color: $mts-card-bg;
  background-clip: border-box;
  border: none;
  margin: 0.75rem 0;
  border-radius: $mts-card-border-radius;

  >hr {
    margin-right: 0;
    margin-left: 0;
  }
}

.mts-card-body {
  flex: 1 1 auto;
  padding: $mts-card-body-padding;
}

.mts-card-flat {
  border-radius: 0 !important;
}

.mts-card-plain {
  background: transparent;
  box-shadow: none;

  .mts-card-header,
  .mts-card-footer {
    margin-left: 0;
    margin-right: 0;
    background-color: transparent;
  }
}

.mts-card-header {
  padding: $mts-card-header-padding;
  margin-bottom: 0;
  border-top-left-radius: $mts-card-border-radius;
  border-top-right-radius: $mts-card-border-radius;
}

.mts-card-footer {
  padding: $mts-card-header-padding;
}

.mts-card-text {
  margin-bottom: 1rem;
}

.mts-card-text:last-child {
  margin-bottom: 0;
}

.mts-card-horizontal {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  align-items: start;
  justify-items: center;
  padding: 1.5rem 0;

  &-title {
    font-size: 12px;
    color: variables.$grey-medium;
    margin-bottom: 8px;
  }

  &-avatar {
    width: 250px;
    margin: 0 4px;
    align-self: center;
  }

  &-item {
    display: flex;
    flex-direction: column;
    margin: 0 24px;
    min-width: 120px;
  }

  &-badges {
    display: inline-block;
  }

  &-action {
    margin: 0 8px;
  }
}