@use "sass:color";
@use 'variables';

.mts-select-container {
  display: inline-block;
  position: relative;
  width: 100%;
  min-width: 125px;
  margin: 0;
}

.mts-selected-option {
  color: variables.$blue-darkest;
  background-color: color.adjust(variables.$white, $lightness: -1%);
  padding: 0.5rem 0.75rem * 3 0.5rem 0.75rem;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  line-height: 1.5;
  border-radius: 8px;
  margin-bottom: 2px;

  &:after {
    content: url('../vector/select-arrow.svg');
    position: absolute;
    right: 20px;
    top: 4px;
  }

  &-active {
    background-color: variables.$blue-light !important;

    &:hover {
      background-color: variables.$blue-light !important;
    }
  }

  &:hover {
    background-color: color.adjust(variables.$grey-light, $lightness: -2.5%);
  }

  &.disabled {
    background-color: variables.$grey;
    pointer-events: none;
  }
}

.mts-selected-option-small {
  padding: 0.25rem 1.75rem 0.25rem 0.5rem !important;

  &:after {
    content: url('../vector/select-arrow.svg');
    position: absolute;
    right: 14px !important;
    top: 1px !important;
  }
}

.mts-select {
  &-options {
    z-index: 1000;
    position: absolute;
    margin-top: 8px;
    width: 100%;
    border: 1px solid variables.$grey-light;
    border-radius: 8px;
    margin: 0 !important;
    padding: 0;
    font-family: 'Lato', sans-serif;
    color: variables.$blue-darkest;
    background-color: variables.$white;

    // > ul {
    //   margin: 0 !important;
    //   padding: 0;
    //   text-align: center;
    //   border-radius: 5px;
    // }

    >li {
      list-style-type: none;
      padding: 0.75rem 1rem;
      border-bottom: 1px solid variables.$grey-light;
      cursor: pointer;

      :last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: variables.$blue-light;
        color: variables.$blue-darkest;
      }
    }
  }

  &-option {
    li {
      list-style-type: none;
      padding: 8px 16px;
      border-bottom: 1px solid #e4e4e4;
      cursor: pointer;

      :last-child {
        border-bottom: none;
      }

      :hover {
        background-color: variables.$blue-darkest;
        color: variables.$white;
      }
    }
  }

  &-show-options {
    min-height: 50px;
    opacity: 1;
    visibility: visible;
  }

  &-hide-options {
    min-height: 0;
    opacity: 0;
    visibility: hidden;
  }
}