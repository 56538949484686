@use './vars.scss' as vars;
@use 'reset';
/** Elements. */
@use 'elements/texts';
@use 'elements/progress-screen';
@use 'elements/horizontal-checklist.scss';

/** Body. */
body {
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

  &::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms;
  }

  &.shadow {
    width: 100%;
    overflow: hidden;

    &::before {
      opacity: 0.6;
      pointer-events: initial;
    }
  }
}

/** Layout. */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  /** Mobile. */
  @media (max-width: 1300px) {
    padding: 0 25px;
  }
}

.container-logged {
  width: 100%;
  max-width: 1270px;
  margin: 0 auto;

  /** Mobile. */
  @media (max-width: 1300px) {
    padding: 0 25px;
  }
}

.select-list {
  z-index: 5;
}

.mento-box {
  border-radius: 6px;
  overflow: hidden;
  padding: 35px 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.mento-box-title {
  margin-top: 20px;
  border-bottom: 1px solid #a0a1b3;
  margin-bottom: 40px;

  span {
    color: #0075f8;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.01em;
    display: inline-block;
    position: relative;
    padding: 0 5px 9px;
  }

}

div#Botsonic-wrapper {
  bottom: 7vh !important;
  right: 1vh !important;

  @media (max-width: 1300px) {
    bottom: 13.2vh !important;
    right: 0.1vh !important;
  }
}

.mask-blue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: vars.get-color(blue, dark);
  opacity: 0.2;
  z-index: 1;
}

.anchor-link-default {
  color: vars.get-color(blue, base)
}

.licirle {
  list-style-type: disc;
}

.li-cirle-margin {
  list-style-type: disc;
  margin-left: 25px;
}

.li-decimal {
  list-style-type: decimal;
}

.li-decimal-margin {
  list-style-type: decimal;
  margin-left: 25px;
}