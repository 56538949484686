@use 'variables';

.bg-transparent {
  background-color: transparent !important;
}

.bg-black {
  background-color: variables.$black !important;
}

.bg-white {
  background-color: variables.$white !important;
}

.bg-blue {
  background-color: variables.$blue !important;
}

.bg-blue-darkest {
  background-color: variables.$blue-darkest !important;
}

.bg-blue-dark {
  background-color: variables.$blue-dark !important;
}

.bg-blue-medium {
  background-color: variables.$blue-medium !important;
}

.bg-blue-branding {
  background-color: variables.$blue-branding !important;
}

.bg-blue-light {
  background-color: variables.$blue-light !important;
}

.bg-green-superlight {
  background-color: variables.$green-superlight !important;
}

.bg-green-light {
  background-color: variables.$green-light !important;
}

.bg-green {
  background-color: variables.$green !important;
}

.bg-green-dark {
  background-color: variables.$green-dark !important;
}

.bg-red {
  background-color: variables.$red !important;
}

.bg-red-superlight {
  background-color: variables.$red-superlight !important;
}

.bg-purple-dark {
  background-color: variables.$purple-dark !important;
}

.bg-purple-superlight {
  background-color: variables.$purple-superlight !important;
}

.bg-grey-dark {
  background-color: variables.$grey-dark !important;
}

.bg-grey-medium {
  background-color: variables.$grey-medium !important;
}

.bg-grey {
  background-color: variables.$grey !important;
}

.bg-grey-light {
  background-color: variables.$grey-light !important;
}

.bg-grey-superlight {
  background-color: variables.$grey-superlight !important;
}

.bg-yellow-dark {
  background-color: variables.$yellow-dark !important;
}

.bg-yellow-darkest {
  background-color: variables.$yellow-darkest !important;
}

.bg-yellow-superlight {
  background-color: variables.$yellow-superlight !important;
}

.bg-yellow-light {
  background-color: variables.$yellow-light !important;
}

.bg-grey-dark-branding {
  background-color: variables.$grey-dark-branding !important;
}