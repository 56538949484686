@use "sass:color";
@use 'variables';

$mts-btn-padding: 12px 24px;
$mts-btn-border-radius: 12px;

.mts-btn {
  font-family: variables.$mts-font-family;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  padding: $mts-btn-padding;
  color: variables.$blue-darkest;
  border-radius: $mts-btn-border-radius;
  border: 1px solid variables.$blue-darkest;
  font-size: 16px;
  line-height: 1.5;
  white-space: nowrap;
  margin: 2px 8px;
  text-decoration: none;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.mts-btn-icon {
  vertical-align: middle;
  margin: 0 4px;
}

.mts-btn-primary {
  color: #ffffff;
  background-color: variables.$blue-darkest;
  border: 1px solid transparent;

  &:hover {
    background-color: color.adjust(variables.$blue-darkest, $lightness: -5%);
  }
}

.mts-btn-sm {
  padding: 4px 12px;
  font-size: 75%;
}

.mts-social-btn {
  width: 32px !important;
  height: 32px !important;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  padding: 0.5rem;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  color: variables.$blue-darkest;
  background-color: variables.$blue-light;
  border: none;
  border-radius: 8px;
  margin: 0 2px;
  cursor: pointer;

  &:hover {
    background-color: color.adjust(variables.$blue-light, $lightness: -2.5%);
  }
}

.mts-icon-btn {
  width: 32px !important;
  height: 32px !important;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  padding: 0.5rem;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  color: variables.$black;
  background-color: variables.$white;
  border: none;
  border-radius: 8px;
  margin: 0 2px;
  cursor: pointer;

  &:hover {
    background-color: color.adjust(variables.$white, $lightness: -1%);
  }
}