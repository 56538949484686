@use 'variables';

.mts-dashboard-layout {
  display: flex;
  font-family: 'Lato', sans-serif;
}

.mts-content-title {
  display: inline-flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
  line-height: 1.5;

  :first-child {
    margin-right: 1rem;
  }

  svg {
    color: variables.$grey-medium;
  }
}

.mts-dashboard-tab {
  margin: 0.25rem 0 1rem;
  padding: 1rem;
  border-radius: variables.$mts-border-radius-xl;
  background-color: variables.$gray;

  &-icon {
    width: 2.5rem;
    height: 2.5rem;
    background-color: variables.$blue-darkest;
    color: variables.$white;
    padding: 0.5rem;
    border-radius: variables.$mts-border-radius-lg;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    text-align: center;

    svg {
      margin: 0 auto;
    }
  }

  &-title {
    color: variables.$blue-darkest;
    margin: 0 0.75rem;
    font-size: 1rem;
  }

  &-count {
    font-size: 2rem;
  }

  &-divider {
    margin: 1rem 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: variables.$grey;
  }
}

.mts-calendar-tab {
  position: relative;
  cursor: pointer;

  &-day {
    font-size: 20px;
    text-align: center;
  }

  &-weekday {
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
  }

  &.active {
    color: variables.$blue-dark !important;

    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 4px;
      border-radius: 4px;
      background-color: variables.$blue-darkest;
      margin: 0 auto !important;
    }
  }
}

.mts-stats {
  width: 35%;
  padding: 0 1.25rem;
}

.mts-scheduled {
  &-month {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 4px;
  }

  &-year {
    font-size: 0.875rem;
    font-weight: 700;
  }

  &-container {
    width: 65%;
    position: relative;
  }

  &-block {
    position: absolute;
    top: -28px;
    right: -32px;
    width: 100%;
    min-width: 750px;
    height: 100%;
    min-height: 100vh;
    padding: 1.5rem 2rem;
    background-color: variables.$gray;
    border-top-left-radius: variables.$mts-border-radius-xl;
    border-bottom-left-radius: variables.$mts-border-radius-xl;
  }
}

@media screen and (max-width: 1500px) {
  .mts-stats {
    width: 40%;
  }

  .mts-scheduled {
    &-container {
      width: 60%;
    }

    &-block {
      right: -32px;
      min-width: 650px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .mts-scheduled {
    &-container {
      width: 60%;
    }

    &-block {
      right: -32px;
      min-width: 625px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .mts-dashboard-layout {
    display: block;
  }

  .mts-stats {
    width: 100%;
    margin-bottom: 2rem;
  }

  .mts-scheduled {
    &-container {
      width: 100%;
    }

    &-block {
      position: relative;
      top: 0;
      right: 0;
      border-radius: variables.$mts-border-radius-xl;
    }
  }
}