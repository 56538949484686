@use 'variables';

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: variables.$grey-light;
}

::-webkit-scrollbar-thumb {
  background-color: #2E333B;
  border-radius: 10em;
  border: 2px solid #eaeaea;
}