@use "sass:color";
@use 'variables';

$mts-form-control-padding: 0.5rem 0.75rem;
$mts-form-control-bg: variables.$white;
$mts-form-control-border: none;
$mts-form-control-border-radius: variables.$mts-border-radius-lg;

.mts-form-control {
  display: block;
  padding: $mts-form-control-padding;
  width: 100%;
  background-color: $mts-form-control-bg;
  border: $mts-form-control-border;
  border-radius: $mts-form-control-border-radius;
  appearance: none;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: variables.$grey-dark;
    opacity: 1;
  }
}

$mts-form-select-padding: 0.5rem 0.75rem * 3 0.5rem 0.75rem;
$mts-form-select-bg: variables.$white;
$mts-form-select-border: 1px solid variables.$white;
$mts-form-select-border-radius: variables.$mts-border-radius-lg;

.mts-form-select {
  display: block;
  width: 100%;
  min-width: 150px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  line-height: 1.5;
  font-size: 1rem;
  color: variables.$blue-darkest;
  padding: $mts-form-select-padding;
  background-color: $mts-form-select-bg;
  border: none;
  border-radius: $mts-form-select-border-radius;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../vector/select-arrow.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 1em center;
  cursor: pointer;

  &:hover {
    background-color: color.adjust(variables.$grey-light, $lightness: -2.5%);
  }

  &.disabled {
    background-color: variables.$grey;
    pointer-events: none;
  }

  &:focus-visible {
    outline: 0;
  }
}

.mts-form-group {
  margin-bottom: 1.25rem;
}

$mts-form-control-padding-lg: 0.75rem 1rem;
$mts-form-control-font-size-lg: 1rem;

.mts-form-control-lg {
  padding: $mts-form-control-padding-lg;
  min-height: 1;
  font-size: $mts-form-control-font-size-lg;
}

textarea {

  &.mts-form-control,
  &.mts-form-control-lg {
    resize: none !important;
  }
}

//Temp - reset label
label {
  display: inline-block; // 1
}

.mts-form-row {
  display: flex;
  gap: 16px;

  &-item {
    flex: 1 1 auto !important;
  }
}

@media screen and (max-width: 768px) {
  .mts-form-row {
    display: flex;
    flex-direction: column !important;
  }
}