@use 'variables';

.mts-sidebar {
  font-family: variables.$mts-font-family;
  max-width: variables.$mts-sidebar-width;
  height: 100vh;
  padding: variables.$mts-sidebar-padding;
  display: flex;
  flex-direction: column;
  background-color: variables.$mts-sidebar-background;
  position: sticky;
  top: 0;

  &-brand {
    cursor: pointer;
    padding: variables.$mts-sidebar-brand-padding;
    background-color: variables.$mts-sidebar-brand-background;
    align-items: center;
    margin: variables.$mts-sidebar-brand-margin;

    img {
      width: 190px;
    }
  }

  &-menu {
    display: flex;
    flex-direction: column;
  }

  &-option {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: variables.$mts-sidebar-option-padding;
    margin: variables.$mts-sidebar-option-margin;
    border-radius: variables.$mts-sidebar-option-border-radius;
    color: variables.$mts-sidebar-option-color;
    // line-height: $mts-sidebar-option-line-height;

    &-icon {
      margin-right: 1rem;
    }

    &:hover {
      color: variables.$mts-sidebar-option-hover-color;
      background-color: variables.$mts-sidebar-option-hover-background;
    }

    &-active {
      color: variables.$mts-sidebar-option-active-color;
      background-color: variables.$mts-sidebar-option-active-background;

      &:hover {
        color: variables.$mts-sidebar-option-active-color;
        background-color: variables.$mts-sidebar-option-active-background;
      }

      ::after {
        content: url('../vector/caretRight.svg');
        position: absolute;
        right: 16px;
      }
    }
  }

  &-footer {
    margin-top: auto;
    margin-bottom: 8px;
  }
}