.mts-spinner-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.mts-spinner-loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #0D55D3;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}