@use 'variables';

$mts-badge-padding: 0.375rem 0.75rem;
$mts-badge-color: variables.$grey-dark;
$mts-badge-margin: 0.125rem;
$mts-badge-border-radius: variables.$mts-border-radius-base;
$mts-badge-font-size: 12px;

.badge {
  display: inline-block;
  padding: $mts-badge-padding;
  font-size: $mts-badge-font-size;
  line-height: 1;
  letter-spacing: 0.01em;
  color: $mts-badge-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  margin: $mts-badge-margin;
  font-family: variables.$mts-font-family;
  border-radius: $mts-badge-border-radius;

  @at-root a#{&} {
    text-decoration: none;
  }

  &:empty {
    display: none;
  }
}

// // Color contrast
// @function color-yiq($color, $dark: $yiq-text-dark, $light: $yiq-text-light) {
//   $r: red($color);
//   $g: green($color);
//   $b: blue($color);

//   $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

//   @if ($yiq >= $yiq-contrasted-threshold) {
//     @return $dark;
//   } @else {
//     @return $light;
//   }
// }