@use '../../sass/vars.scss' as vars;

.horizontal-checklist-container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0 100px;
    height: 80px;

    @media (max-width: 1023px) {
        padding: 0;
        margin: 0;
    }

    .horizontal-checklist {
        width: 100%;
        padding-top: 20px;
        padding-right: 5px;

        &-progressbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            counter-reset: step;
        }

        &-progressbar .horizontal-checklist--item {
            position: relative;
            width: 100%;
            margin: 0 3px;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto;

            &:hover {
                cursor: pointer;
            }

            @media (max-width: 1023px) {
                font-size: 0.65em;
                width: 100%;
                margin: 0 1px;
            }
        }

        &-progressbar .horizontal-checklist--item .dashed-line {
            position: absolute;
            top: 17.5px;
            border: none;
            border-top: 2px dashed white;
            color: #fff;
            height: 1px;
            width: 100%;

            @media (max-width: 1023px) {
                border-top: 1px dashed white;
            }
        }

        &-progressbar .horizontal-checklist--item:first-child .dashed-line {
            right: 0;
            width: 50%;
        }

        &-progressbar .horizontal-checklist--item:last-child .dashed-line {
            left: 0;
            width: 50%;
        }


        &-progressbar li {
            padding: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            content: counter(step);
            counter-increment: step;
            line-height: 30px;
            border: 2px solid vars.get-color(red);
            ;
            text-align: center;
            border-radius: 50%;
            background-color: white;
            font-weight: 500;
            font-size: 1.4em;
            color: vars.get-color(red);
            transition: ease-in-out 0.3s;

            &:hover {
                background-color: vars.get-color(red);
                border: 2px solid white;
                color: white;
            }

            @media (max-width: 1023px) {
                font-size: 1.8em;
            }
        }

        &-progressbar span {
            padding-top: 10px;
        }

        &-progressbar li:first-child:after {
            content: none;
        }

        &-progressbar li.horizontal-checklist-active {
            border-color: white;
            background-color: vars.get-color(green);
            color: white;
        }
    }
}