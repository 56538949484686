@use 'variables';

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.vertical-align-middle {
  vertical-align: middle;
}

.new-line {
  white-space: pre-line;
}

.text-error {
  font-family: 'Montserrat', 'Lato', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: variables.$red !important;
}