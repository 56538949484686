@use 'variables';

$variable-prefix: mts-;

$mts-table-color: variables.$grey-dark;
$mts-table-bg: transparent;
$mts-table-accent-bg: transparent;

$mts-table-striped-color: $mts-table-color;
// $mts-table-striped-bg-factor: .05;
$mts-table-striped-bg: variables.$grey-superlight;

$mts-table-border-radius: variables.$mts-border-radius-xl;
$mts-table-cell-padding: 1.25rem 0.75rem;

$mts-table-thead-padding: 0.25rem 0.5rem;
$mts-table-thead-bg: variables.$grey-light;

$mts-table-th-padding: 1.75rem 0.5rem 1.25rem;

$mts-table-striped-order: even;

.mts-table {
  --#{$variable-prefix}table-bg: #{$mts-table-bg};
  --#{$variable-prefix}table-accent-bg: #{$mts-table-accent-bg};
  --#{$variable-prefix}table-striped-color: #{$mts-table-striped-color};
  --#{$variable-prefix}table-striped-bg: #{$mts-table-striped-bg};
  // --#{$variable-prefix}table-active-color: #{$table-active-color};
  // --#{$variable-prefix}table-active-bg: #{$table-active-bg};
  // --#{$variable-prefix}table-hover-color: #{$table-hover-color};
  // --#{$variable-prefix}table-hover-bg: #{$table-hover-bg};
  font-family: 'Lato', sans-serif;
  width: 100%;
  color: $mts-table-color;
  vertical-align: top;
  border-collapse: collapse !important;
  overflow: hidden !important;
  border-radius: $mts-table-border-radius !important;

  > :not(caption)>*>* {
    padding: $mts-table-cell-padding;
    vertical-align: middle;
  }

  >tbody {
    vertical-align: inherit;
    font-size: 0.875rem;
  }

  >thead {
    vertical-align: bottom;
    padding: $mts-table-thead-padding;
    background-color: $mts-table-thead-bg;
  }

  th {
    padding: $mts-table-th-padding;
    font-size: 0.875rem;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
  }

  &-first-column {
    width: 40px;
  }
}

.mts-table-td-flex {
  display: flex;
  align-items: center;
  flex-direction: row;
}

// .mts-table-striped {
//   tr:nth-child(even) {
//     background-color: $mts-table-striped-bg;
//   }
// }

.mts-table-striped {
  >tbody>tr:nth-of-type(#{$mts-table-striped-order}) {
    background-color: var(--#{$variable-prefix}table-striped-bg);
  }
}

// .table-active {
//   --#{$variable-prefix}table-accent-bg: var(
//     --#{$variable-prefix}table-active-bg
//   );
//   color: var(--#{$variable-prefix}table-active-color);
// }

// .table-hover {
//   > tbody > tr:hover {
//     --#{$variable-prefix}table-accent-bg: var(
//       --#{$variable-prefix}table-hover-bg
//     );
//     color: var(--#{$variable-prefix}table-hover-color);
//   }
// }