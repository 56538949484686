@use 'variables';

$mts-filter-bar-padding: 1.25rem 1.5rem;
$mts-filter-bar-color: variables.$grey-dark;
$mts-filter-bar-background: variables.$blue-light;
$mts-filter-bar-border-radius: variables.$mts-border-radius-xl;

.mts-filter-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $mts-filter-bar-padding;
  color: $mts-filter-bar-color;
  background-color: $mts-filter-bar-background;
  font-family: 'Lato', sans-serif;
  font-size: 0.875rem;
  border-radius: $mts-filter-bar-border-radius;
  margin-bottom: 2rem;

  &-fields {
    display: flex;
    align-items: center;
    max-width: 300px;

    span {
      white-space: nowrap;
      margin: 0 0.5rem;
    }
  }

  &-order {
    display: flex;
    align-items: center;
    max-width: 350px;
  }

  .delete-btn {
    display: flex;
    align-items: flex-end;
    color: variables.$red-dark;
  }
}