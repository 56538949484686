@use 'variables';

.text-black {
  color: variables.$black !important;
}

.text-white {
  color: variables.$white !important;
}

.text-blue {
  color: variables.$blue !important;
}

.text-blue-darkest {
  color: variables.$blue-darkest !important;
}

.text-blue-dark {
  color: variables.$blue-dark !important;
}

.text-blue-medium {
  color: variables.$blue-medium !important;
}

.text-blue-branding {
  color: variables.$blue-branding !important;
}

.text-blue-light {
  color: variables.$blue-light !important;
}

.text-green-superlight {
  color: variables.$green-superlight !important;
}

.text-green {
  color: variables.$green !important;
}

.text-green-dark {
  color: variables.$green-dark !important;
}

.text-red {
  color: variables.$red !important;
}

.text-red-superlight {
  color: variables.$red-superlight !important;
}

.text-purple-dark {
  color: variables.$purple-dark !important;
}

.text-purple-superlight {
  color: variables.$purple-superlight !important;
}

.text-grey-dark {
  color: variables.$grey-dark !important;
}

.text-grey-medium {
  color: variables.$grey-medium !important;
}

.text-grey {
  color: variables.$grey !important;
}

.text-grey-light {
  color: variables.$grey-light !important;
}

.text-grey-superlight {
  color: variables.$grey-superlight !important;
}

.text-yellow-dark {
  color: variables.$yellow-dark !important;
}

.text-yellow-darkest {
  color: variables.$yellow-darkest !important;
}

.text-yellow-superlight {
  color: variables.$yellow-superlight !important;
}

.text-yellow-light {
  color: variables.$yellow-light !important;
}