@use "sass:color";
@use 'variables';

.mts-drawer {
  height: 100%;
  max-height: 100vh;
  font-family: variables.$mts-font-family;
  background-color: variables.$white;
  position: fixed;
  top: 0;
  right: 0;
  // width: 40%;
  width: 600px;
  z-index: 200;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  &-open {
    transform: translateX(0);
  }

  &-content {
    height: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  &-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.25rem;
    font-family: variables.$mts-font-family !important;

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 12px;

      &-name {
        font-size: 20px;
        color: variables.$black;
        margin: 0 0 2px;
        font-weight: 700;
      }

      &-city {
        font-size: 13px;
        color: variables.$grey-medium;
        font-weight: 900;
        margin: 0 0 2px;
      }

      &-price {
        font-size: 12px;
        color: variables.$grey-dark;
        font-weight: 700;
      }
    }
  }

  &-body {
    margin: 8px 0;
  }

  &-footer {
    margin-top: auto;
  }
}

.mts-backdrop {
  // opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 0;
  right: 0;
  // transform: translateX(100%);
  // transition: transform 0.2s ease-out;
  overflow: hidden !important;

  &-open {
    // display: block !important;
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.2s ease-out;
  }
}

.mts-backdrop-drawer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

//temp

.mts-talkmentor-btn {
  margin: 4px 0;
  padding: 16px;
  border-radius: variables.$mts-border-radius-lg;
  background-color: variables.$blue-light;
  text-decoration: none;
  cursor: pointer;

  &-icon {
    color: variables.$blue-darkest;
  }

  &-info {
    margin: 0 12px;
    vertical-align: middle;

    span {
      color: variables.$grey-medium;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 1px;
    }

    h3 {
      color: variables.$black;
      margin: 0;
    }
  }

  &:hover {
    background-color: color.adjust(variables.$blue-light, $lightness: -2.5%);
  }
}

.mts-schedule-btn {
  margin: 4px 0;
  padding: 16px;
  border-radius: variables.$mts-border-radius-lg;
  background-color: variables.$green-superlight;
  text-decoration: none;
  cursor: pointer;

  &-icon {
    color: variables.$green-dark;
  }

  &-info {
    margin: 0 12px;
    vertical-align: middle;

    span {
      color: variables.$grey-medium;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 1px;
    }

    h3 {
      color: variables.$black;
      margin: 0;
    }
  }

  &:hover {
    background-color: color.adjust(variables.$green-superlight, $lightness: -2.5%);
  }
}