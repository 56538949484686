// *,
// *::before,
// *::after {
//   box-sizing: border-box;
// }
@use 'variables';

body {
  font-family: variables.$mts-font-family;
  background-color: variables.$mts-body-bg !important;
}

.mts-layout {
  text-rendering: optimizeLegibility !important;
  display: flex;

  &-content {
    width: 100%;
    padding: 1.75rem 2rem;
    background-color: variables.$white;
  }

  &-review-mentoring {
    display: grid;
    grid-template-areas: 'Form Form Form Form UserInfo';
    gap: 16px;

    .form-block {
      grid-area: Form;
    }

    .user-block {
      grid-area: UserInfo;
      position: relative;

      .header-sticky {
        z-index: 45;
        position: sticky;
        top: 120px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .mts-layout {
    &-review-mentoring {
      display: grid;
      grid-template-areas:
        'UserInfo'
        'Form';
    }
  }
}